<template>
  <div class="mx-8 h-full">
    <div v-if="reportLoading" class="h-full flex justify-center items-center">
      <base-spinner />
    </div>
    <div v-else class="h-full">
      <div class="header--report">
        <div class="header--modal">
          <div class="truncate">
            {{ $t(`${nameReport}.name`) }}
          </div>
        </div>
        <div class="flex absolute right-0 ">
          <report-dua-date
            :is-select-time="true"
            :default-date-this-week-calendar="defaultDateThisWeekCalendar"
            class="calendar"
            @changed="selectTypetime"
            @datePick="range"
          />
          <base-button
            color="brand"
            wide="px-10"
            class="font-medium ml-5"
            data-testid="save-btn "
            :disabled="loading || isEmpty"
            :loading="loading"
            @click="expertPDF"
          >
            {{ $t('export') }}</base-button
          >
        </div>
      </div>
      <div v-if="isEmpty" class="h-full flex justify-center items-center">
        <base-empty :image="require('@/assets/image/no-board-report.svg')">
          <div class="text-lg capitalize font-medium text-black">
            {{ $t('empty.header') }}
          </div>
          <div class="font-normal text-desciption ">
            {{ $t('empty.description1') }}
          </div>
          <div class="font-normal text-desciption ">
            {{ $t('empty.description2') }}
          </div>
        </base-empty>
      </div>
      <div v-else>
        <div
          class="grid grid-flow-col grid-cols-3  rounded border-gray-200 border-2"
        >
          <div class="total--time border-gray-200 border-r-2">
            <p>{{ $t('total_task') }}</p>
            <div class="pt-1">
              <span class="number--totaltime">{{ report.TotalTasks }} </span>
              <span class="text-base">{{
                $tc('report.totalTask', report.TotalTasks)
              }}</span>
            </div>
          </div>
          <div class="total--time border-gray-200 border-r-2">
            <div class="flex">
              <p v-if="typeTime === 1">{{ $t('total_estimate') }}</p>
              <p v-else>{{ $t('total_time_log') }}</p>
              <base-popup v-show="typeTime === 2" multiline size="is--wide">
                <base-icon
                  class="pointer ml-1 p-1 justify-self-center"
                  icon-size="18"
                >
                  <icon-alert-circle />
                  />
                </base-icon>
                <div slot="content" class="text-left">
                  <span>
                    {{ $t('report.tooltip.timelog') }}
                  </span>
                </div>
              </base-popup>
            </div>
            <span v-if="time.hr > 0">
              <span class="number--totaltime">{{ time.hr }} </span>
              <span class="text-base">
                {{ $tc('report.totalTimeHr', time.hr) }}
              </span>
            </span>
            <span>
              <span class="number--totaltime">{{ time.min }} </span>
              <span class="text-base">
                {{ $tc('report.totalTimeMin', time.min) }}
              </span>
            </span>
          </div>
          <div class="total--time">
            <p>{{ $t('total_member') }}</p>
            <div class="pt-1">
              <span class="number--totaltime">{{ report.TotalMembers }} </span>
              <span class="text-base">{{
                $tc('report.totalMembers', report.TotalMembers)
              }}</span>
            </div>
          </div>
        </div>

        <div
          v-if="isProgress"
          class=" rounded border-gray-main-dark-light border-2 mt-5"
        >
          <div class="pl-5 py-3 bg-gray-main">
            <p class="text-black font-medium text-sm">
              {{ $t('complete_task') }}
            </p>
          </div>
          <div class="report-graph">
            <img :src="report.GraphImage" />
          </div>
        </div>
        <!-- table user -->
        <p class="text-base font-medium pt-5">{{ $t('user') }}</p>
        <div class="header--table-task">
          <div class="col-span-8">
            <div class="pl-6">{{ $t('table.name') }}</div>
          </div>
          <div class="col-span-2">
            <div>{{ $t('table.task') }}</div>
          </div>
          <div class="col-span-2">
            <div class="flex">
              <span v-if="typeTime === 1">{{ $t('table.estimate') }}</span>
              <span v-else>{{ $t('table.timelog') }}</span>
              <base-popup multiline position="bottom-right" size="is--wide">
                <base-icon
                  class="pointer p-1 justify-self-center"
                  icon-size="18"
                  name=""
                >
                  <icon-alert-circle />
                  />
                </base-icon>
                <div
                  slot="content"
                  class="text-sm normal-case font-normal text-left"
                >
                  <div v-if="typeTime === 1">
                    <span>
                      {{ $t('report.tooltip.estimate') }}
                    </span>
                  </div>
                  <div v-else>
                    <span>
                      {{ $t('report.tooltip.timelog') }}
                    </span>
                  </div>
                </div>
              </base-popup>
            </div>
          </div>
        </div>

        <div class="border--table">
          <table class="min-w-max w-full my-1">
            <tbody class="text-sm font-normal">
              <tr
                v-for="(member, index) in members"
                :key="index"
                class="py-4 "
                :class="{ 'border-b-1': members.length - 1 !== index }"
              >
                <td class="flex items-center">
                  <img :src="member.ProfilePicture" />
                  <span class="py-2" style="width: 480px;">
                    <p class="text-gray-800 text-sm truncate">
                      {{ member.Name }}
                    </p>
                    <p class="text-xs text-gray-500 font-normal">
                      {{ member.Email }}
                    </p>
                  </span>
                </td>
                <td class="pl-2 w-1/6">
                  <span v-if="$i18n.locale == 'th'">
                    {{ member.TotalTasks }}
                  </span>
                  {{ member.TotalTasksString }}
                </td>
                <td class="pl-3 w-1/6">
                  <span v-if="typeTime === 1">
                    {{ member.TotalEstimateTimeString }}</span
                  >
                  <span v-else>{{ member.TotalTimeSpentString }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pt-5">
          <div class="text-base font-medium">
            <p v-if="isProgress">
              {{ $t('all_task') }} {{ `(${report.StartAtEndAt})` }}
            </p>
            <p v-else>{{ $t('all_task') }}</p>
          </div>
          <p class="text-sm font-normal	text-gray-500">
            {{ $t('task_during') }}
            {{ `(${report.StartAtEndAt})` }}
          </p>
        </div>

        <div class="report--table-header">
          <table class="my-1 w-full">
            <tr>
              <td class="w-3/6">
                <div>{{ $t('table.name') }}</div>
              </td>
              <td class="w-1/6">
                <div>{{ $t('table.assignee') }}</div>
              </td>
              <td class="w-1/6">
                <div v-if="nameReport === 'progress'">
                  <span v-if="typeTime === 1">{{ $t('table.estimate') }}</span>
                  <span v-else>{{ $t('table.timelog') }}</span>
                </div>
                <div v-else>{{ $t('table.start_end') }}</div>
              </td>
              <td class="w-2/6">
                <div v-if="isProgress" class="pl-2 progress-description">
                  {{ $t('table.description') }}
                </div>
                <div v-else class="flex">
                  <span v-if="typeTime === 1">{{ $t('table.estimate') }}</span>
                  <span v-else>{{ $t('table.timelog') }}</span>
                  <base-popup multiline position="bottom-right" size="is--wide">
                    <base-icon
                      class="pointer p-1 justify-self-center"
                      icon-size="18"
                      name=""
                    >
                      <icon-alert-circle />
                      />
                    </base-icon>
                    <div
                      slot="content"
                      class="text-sm normal-case font-normal text-left"
                    >
                      <div v-if="typeTime === 1">
                        <span>
                          {{ $t('report.tooltip.estimate') }}
                        </span>
                      </div>
                      <div v-else>
                        <span>
                          {{ $t('report.tooltip.timelog') }}
                        </span>
                      </div>
                    </div>
                  </base-popup>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="border--table">
          <table class="min-w-max w-full my-1">
            <tbody class="text-sm font-normal">
              <tr
                v-for="(allTask, index) in allTasks"
                :key="index"
                class="h-16 "
                :class="{ 'border-b-1': allTasks.length - 1 !== index }"
              >
                <td class="w-3/6" :class="{ 'align-top': isProgress }">
                  <v-clamp
                    class="text-sm pr-10 break-words"
                    autoresize
                    :max-lines="10"
                  >
                    {{ allTask.Name }}
                  </v-clamp>
                  <div
                    v-if="isProgress && allTask.CompletedAt"
                    class="progress--task-complete"
                  >
                    <base-icon size="15" class="mr-1">
                      <icon-completed />
                    </base-icon>
                    {{ $t('complete_at') }} {{ allTask.CompletedAt }}
                  </div>
                </td>
                <td class="w-1/6" :class="{ 'align-top': isProgress }">
                  <base-avatar :list="getUsersFromAssigns(allTask.Assignees)" />
                </td>
                <td class="w-1/6" :class="{ 'align-top': isProgress }">
                  <div v-if="isProgress">
                    <span v-if="typeTime === 1">
                      {{ allTask.TotalEstimateTimeString }}</span
                    >
                    <span v-else>{{ allTask.TotalTimeSpentString }}</span>
                  </div>
                  <div v-else>
                    {{ allTask.StartAtEndAt }}
                  </div>
                </td>
                <td class="w-2/6" :class="{ 'align-top pl-3': isProgress }">
                  <div v-if="isProgress" class="progress-description">
                    <v-clamp
                      class="text-sm break-words"
                      autoresize
                      :max-lines="10"
                    >
                      {{ replaceHtmlTag(allTask.Description) }}
                    </v-clamp>
                  </div>
                  <div v-else>
                    <span v-if="typeTime === 1">{{
                      allTask.TotalEstimateTimeString
                    }}</span>
                    <span v-else>{{ allTask.TotalTimeSpentString }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from '@/services/apis';
import IconAlertCircle from '@/components/icons/IconAlertCircle';
import ReportDuaDate from '@/components/report/report-due-date';
import LoadingMixin from '@/mixins/LoadingMixin';
import IconCompleted from '@/components/icons/IconCompleted';
import VClamp from 'vue-clamp';

import moment from 'moment';
export default {
  mixins: [LoadingMixin],
  data() {
    return {
      reportLoading: false,
      report: [],
      members: [],
      allTasks: [],
      user: {
        profilePicture: ''
      },
      typeTime: 1
    };
  },
  components: {
    IconAlertCircle,
    ReportDuaDate,
    IconCompleted,
    VClamp
  },
  computed: {
    isEmpty() {
      return (
        !this.report.TotalTasks &&
        !this.report.TotalMembers &&
        !this.time.hr &&
        !this.time.min
      );
    },
    nameReport() {
      return this.$route.params.type;
    },
    boardID() {
      return parseInt(this.$route.params.id);
    },
    defaultDate() {
      const lastDay = moment(this.defaultDateThisWeekCalendar.start).subtract(
        1,
        'day'
      );
      const start = moment(lastDay).format('YYYY-MM-DD[T17:00:00Z]');
      const end = moment(this.defaultDateThisWeekCalendar.end).format(
        'YYYY-MM-DD[T16:59:59Z]'
      );
      const formatTimeZone = f => moment(f).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      return {
        start: formatTimeZone(start),
        end: formatTimeZone(end)
      };
    },
    defaultDateThisWeekCalendar() {
      return {
        start: moment()
          .startOf('week')
          .toDate(),
        end: moment()
          .endOf('week')
          .toDate()
      };
    },
    time() {
      const totoalTimeMinute =
        this.typeTime === 1
          ? this.report.TotalEstimateTime
          : this.report.TotalTimeSpent;
      const hour = Math.floor(totoalTimeMinute / 60);
      const minute = totoalTimeMinute % 60;
      return {
        hr: hour,
        min: minute
      };
    },
    isProgress() {
      return this.$route.params.type === 'progress';
    }
  },
  watch: {
    $route: 'setFetchReports'
  },
  mounted() {
    this.setFetchReports();
  },
  methods: {
    replaceHtmlTag(textOriginal) {
      const textReplace = textOriginal.replace(/<\/?[^>]+>/gi, ' ');
      return textReplace || '-';
    },
    setFetchReports() {
      const lastDay = moment(this.defaultDateThisWeekCalendar.start).subtract(
        1,
        'day'
      );
      const start = moment(lastDay).format('YYYY-MM-DD[T17:00:00Z]');
      const end = moment(this.defaultDateThisWeekCalendar.end).format(
        'YYYY-MM-DD[T16:59:59Z]'
      );
      this.typeTime = 1;
      this.reportLoading = true;
      this.fetchReport(start, end);
    },
    selectTypetime(e) {
      this.typeTime = e;
      this.fetchReport(this.defaultDate.start, this.defaultDate.end);
    },
    async range({ dateRange }) {
      const lastDay = moment(dateRange.start).subtract(1, 'day');
      this.defaultDate.start = moment(lastDay).format('YYYY-MM-DD[T17:00:00Z]');
      this.defaultDate.end = moment(dateRange.end).format(
        'YYYY-MM-DD[T16:59:59Z]'
      );
      this.fetchReport(this.defaultDate.start, this.defaultDate.end);
    },
    async expertPDF() {
      this.onLoading();

      const res = await apis.get(
        `boards/${this.boardID}/reports/${this.nameReport}/export`,
        {
          params: {
            startAt: this.defaultDate.start,
            endAt: this.defaultDate.end,
            reportTimeType: this.typeTime
          },
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf'
          }
        }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `report-${this.nameReport}.pdf`);
      document.body.appendChild(link);
      link.click();

      this.offLoading();
    },
    getUsersFromAssigns(assigns) {
      const filterUser = assigns
        .filter(e => e.ProfilePicture != '')
        .map(
          e =>
            (this.user = {
              profilePicture: e.ProfilePicture
            })
        );
      return filterUser;
    },
    async fetchReport(dateStart, dateEnd) {
      await apis
        .get(`boards/${this.boardID}/reports/${this.nameReport}`, {
          params: {
            startAt: dateStart,
            endAt: dateEnd,
            reportTimeType: this.typeTime
          }
        })
        .then(response => {
          const { data } = response;
          this.report = data;
          this.members = data.Members;
          this.allTasks = data.Tasks;
          this.reportLoading = false;
        });
    }
  }
};
</script>
<style scoped>
.progress--task-complete {
  @apply text-gray-caption font-normal pt-1 flex items-center;
  font-size: 13px;
}
.progress-description {
  width: 200px;
}
.header--modal {
  @apply my-2 text-2xl capitalize items-center font-medium absolute left-0;
  width: 370px;
}
.total--time {
  @apply font-medium my-4 mx-8 pl-3;
}
.total--time p {
  @apply text-sm font-medium capitalize;
}
.total--time .number--totaltime {
  @apply font-semibold;
  font-size: 28px;
}
img {
  @apply mr-2 h-8 w-8 rounded-full object-cover;
}
.header--table-task {
  @apply grid-cols-12 grid pt-2 font-medium text-xs text-gray-700 uppercase;
  color: #7a869a;
}
.border--table {
  @apply border border-gray-200 rounded-r px-5;
}
.report-graph img {
  @apply w-full h-full rounded-none p-5;
}
.report--table-header {
  @apply px-5 font-medium text-xs uppercase pt-2;
  color: #7a869a;
}
.calendar {
  -webkit-transform: translate3d(0, 0, 0);
}
.header--report {
  @apply relative mt-5;
  height: 52px;
}
</style>

<i18n>
{
  "en": {
    "planning":{
      "name": "Planning report"
    },
    "progress":{
      "name": "Progress report"
    },
    "export": "Export PDF",
    "user": "Users / Resources",
    "all_task": "All Tasks",
    "task_during": "Tasks due during",
    "table": {
      "name": "TASKS NAME",
      "assignee": "ASSIGNEE",
      "start_end": "Start/End Date",
      "estimate": "ESTIMATE TIME",
      "timelog": "Time Log",
      "task": "TASK",
      "description": "DESCRIPTION" 
    },
    "empty": {
      "header": "not enough information",
      "description1": "Member information not found. Please select a member to",
      "description2": "submit the report."
    },
    "total_task": "Total tasks",
    "total_estimate": "Total estimate time",
    "total_member": "Total members",
    "min": "min",
    "mins": "{count} min |{count} mins",
    "hr": "hr",
    "hrs": "hrs",
    "total_time_log": "Total Time Log",
    "complete_at": "Complete at",
    "complete_task": "Complete Tasks",
    "task": "{count} task |{count} tasks"
  },

  "th": {
    "planning":{
      "name": "รายงานแผนงาน"
    },
    "progress":{
      "name": "รายงานความคืบหน้า"
    },
    "export": "ออกรายงาน PDF",
    "user": "ผู้ใช้ / ทรัพยากร",
    "all_task": "งานทั้งหมด",
    "task_during": "งานที่ครบกำหนด",
    "table": {
      "name": "ชื่องาน",
      "assignee": "ผู้รับผิดชอบงาน",
      "start_end": "วันที่เริ่ม / สิ้นสุด",
      "estimate": "ประเมินเวลา",
      "timelog": "บันทึกเวลา",
      "task": "งาน",
      "description": "คำอธิบาย" 
    },
      "empty": {
      "header": "ไม่พบข้อมูล",
      "description1": "กรุณาเลือกประเภทรายงาน, ช่วงเวลา, ",
      "description2": "และประเภทเวลาของรายงาน เพื่อออกรายงาน"
    },
    "total_task": "จำนวนงานทั้งหมด",
    "total_estimate": "จำนวนเวลาที่ประเมินทั้งหมด",
    "total_member": "จำนวนสมาชิกทั้งหมด",
    "min": "นาที",
    "mins": "{count} นาที",
    "hr": "ชม.",
    "hrs": "ชม.",
    "total_time_log": "จำนวนเวลาที่บันทึกทั้งหมด",
    "complete_at": "เสร็จสิ้นเมื่อ",
    "complete_task": "งานที่เสร็จแล้ว",
    "task": "{count} งาน"
  }
}
</i18n>
